import { Box } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text as TextDS } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { PhoneIcon, TickIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'
import { Spacer } from 'common/components/molecules/Spacer'
import { useDesktop } from 'common/hooks/useDesktop'

export const CalculatorCards: React.FC<any> = () => {
    const isDesktop = useDesktop()
    return (
        <Box
            display={isDesktop ? 'inline-flex' : ''}
            width={'-webkit-fill-available'}
        >
            <Card p='32px 24px 40px' marginRight={isDesktop ? '12px' : ''}>
                <TextDS variant='title-md'>Call us</TextDS>
                <TextDS my={4}>
                    If you have any questions about your quote.
                </TextDS>
                <Button leftIcon={<PhoneIcon w={6} h={6} />}>
                    <a href='tel://+35317041104'>Call: +353 (0)1 704 1104</a>
                </Button>
            </Card>
            {!isDesktop && <Spacer variant='xs' />}
            <Card p='32px 24px 40px'>
                <TextDS variant='title-md' mb={4}>
                    Why choose Irish Life Assurance?
                </TextDS>
                <Box display='flex' alignItems='center' mb={3}>
                    <TickIcon color='accent.500' w={5} h={5} mr={1.5} />
                    <TextDS>98.1% of death claims paid (2022)</TextDS>
                </Box>
                <Box display='flex' alignItems='center' mb={3}>
                    <TickIcon color='accent.500' w={5} h={5} mr={1.5} />
                    <TextDS>
                        Supporting 7,000+ families with claims (2022)
                    </TextDS>
                </Box>
                <Box display='flex' alignItems='center' mb={3}>
                    <TickIcon color='accent.500' w={5} h={5} mr={1.5} />
                    <TextDS>
                        80 years looking after the needs of people in Ireland
                    </TextDS>
                </Box>
                <Box display='flex' alignItems='center' mb={3}>
                    <TextDS>*(Irish Life Assurance, 2022)</TextDS>
                </Box>
            </Card>
        </Box>
    )
}

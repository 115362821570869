import { Box } from '@chakra-ui/react'
import { HotStepper } from '@irishlifedigitalhub/ds.molecules.hot-stepper'
import React from 'react'
import { useCalculatorMachine } from '../utils/context'

export interface ProgressIndicatorProps {}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = () => {
    const { state } = useCalculatorMachine()

    let currentStep = 1

    switch (true) {
        case (state.context.quoteData as any)?.projection > 0:
            currentStep = 4
            break
        case state.context.screenNo === 0:
            currentStep = 1
            break
        case state.context.screenNo === 1:
            currentStep = 2
            break
        case state.context.screenNo === 2:
            currentStep = 3
            break
        default:
            currentStep = 4
    }

    return (
        <Box id='ProgressIndicator' mb='16px' pb='18px'>
            <HotStepper
                currentStep={currentStep}
                steps={[
                    {
                        title: 'Status',
                    },
                    {
                        title: 'Calculate',
                    },
                    {
                        title: 'About you',
                    },
                    {
                        title: 'Pension',
                    },
                ]}
            />
        </Box>
    )
}

export default ProgressIndicator

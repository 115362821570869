import React from 'react'
import { Box } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { getStyles } from '../../styles/getStyles'
import { Input } from '@irishlife/ilgroupdesignsystem.molecules.input'
import { CalculatorInputType } from 'common/components/organisms/Calculator/utils/interfaces'

export const DefaultInput: React.FC<CalculatorInputType> = ({
    register,
    placeholder,
    label,
    fieldName,
    inputtype,
    control,
    errorMessage,
    setIsTouched,
    isInvalid,
}) => {
    return (
        <>
            <Controller
                control={control}
                name={fieldName ?? ''}
                defaultValue=''
                rules={{
                    required: errorMessage,
                }}
                render={({ onChange, onBlur, value }) => (
                    <Box sx={getStyles({ isInvalid })}>
                        <Input
                            onWheel={(e: any) => {
                                if (inputtype === 'number') {
                                    const target = e.target as HTMLInputElement
                                    target.type = 'text'
                                    setTimeout(() => {
                                        target.type = 'number'
                                    }, 0)
                                }
                            }}
                            type={inputtype === 'number' ? 'number' : 'text'}
                            key={fieldName}
                            label={label}
                            name={fieldName}
                            ref={register}
                            placeholder={placeholder}
                            onBlur={() => setIsTouched && setIsTouched(true)}
                        />
                    </Box>
                )}
            />
        </>
    )
}

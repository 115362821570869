import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { RichText } from 'common/components/molecules/RichText'
import _ from 'lodash'
import React from 'react'
import { CalculatorStepBinaryChoice } from './CalculatorStepBinaryChoice'
import { CalculatorStepInput } from './CalculatorStepInput'
import { CalculatorStepMultipleChoice } from './CalculatorStepMultipleChoice'
import { CalculatorStepText } from './CalculatorStepText'

export interface CalculatorStepProps {
    props: any
    fieldName?: string
    register?: () => {}
    handleOnChange?: (name: string, event: any) => void
    control?: any
    error?: any
}
export const CalculatorStep: React.FC<any> = ({
    props,
    register,
    handleOnChange,
    control,
    errors,
}) => {
    switch (props.type) {
        case 'calculatorstepbinarychoice':
            return (
                <Box pb='24px'>
                    <CalculatorStepBinaryChoice
                        {...props}
                        control={control}
                        handleOnChange={handleOnChange}
                        updateData={props.updateData || _.noop}
                    />
                    {props.helpertext && (
                        <RichText label={props.helpertext} html></RichText>
                    )}
                </Box>
            )
        case 'calculatorstepmultiplechoice':
            return (
                <Box pb='24px'>
                    <CalculatorStepMultipleChoice
                        {...props}
                        control={control}
                        handleOnChange={handleOnChange}
                        updateData={props.updateData || _.noop}
                    />
                    {props.helpertext && (
                        <RichText label={props.helpertext} html></RichText>
                    )}
                </Box>
            )
        case 'calculatorstepinput':
            return (
                <Box pb='24px'>
                    <CalculatorStepInput
                        {...props}
                        errors={errors}
                        register={register}
                        control={control}
                        updateData={props.updateData || _.noop}
                        helperText={props.helpertext}
                        errorMessage={props.errorMessage}
                    />
                    {/* {props.helpertext && (
                        <RichText label={props.helpertext} html></RichText>
                    )} */}
                </Box>
            )
        case 'calculatorsteptext':
        default:
            return (
                <Box pb='24px'>
                    <CalculatorStepText {...props} />
                    {props.helpertext && (
                        <Text variant={'label-md'}>{props.helpertext}</Text>
                    )}
                </Box>
            )
    }
}

import React from 'react'
import { useCalculatorMachine } from '../../utils/context'
import { ErrorScreen } from '../ErrorScreen'
import { PensionCalcQuote } from '../quoteComponents/PensionCalculatorQuote'
import { CalculatorStepCommon } from '../steps/CalculatorStepCommon'

export const ViewSelector: React.FC<any> = (props) => {
    const QuoteComponent = PensionCalcQuote
    const { state } = useCalculatorMachine()
    switch (true) {
        case state.matches('Quote Screen') ||
            state.matches('Refetching Quote Details'):
            return <QuoteComponent />
        case state.matches('Error'):
            return <ErrorScreen />
        default:
            return <CalculatorStepCommon {...props} />
    }
}

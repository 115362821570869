import { Box } from '@chakra-ui/react'
import { Alert } from '@irishlifedigitalhub/ds.molecules.alert'
import React from 'react'

export interface WarningAlertsProps {}

export const WarningAlerts: React.FC<WarningAlertsProps> = () => {
    return (
        <>
            <Box pt='12px' pb={'60px'}>
                <Box pb={'8px'}>
                    <Alert
                        borderColor={{
                            color: 'var(--Monochrome-900, #4E4670)',
                        }}
                    >
                        Warning: The value of your investment may go down as
                        well as up.
                    </Alert>
                </Box>
                <Box pb={'8px'}>
                    <Alert
                        borderColor={{
                            color: 'var(--Monochrome-900, #4E4670)',
                        }}
                    >
                        Warning: If you invest in this product you may lose some
                        or all of the money you invest.
                    </Alert>
                </Box>
                <Box pb={'8px'}>
                    <Alert
                        borderColor={{
                            color: 'var(--Monochrome-900, #4E4670)',
                        }}
                    >
                        Warning: If you invest in this product you will not have
                        any access to your money until age 60 and/or you retire.
                    </Alert>
                </Box>
            </Box>
        </>
    )
}

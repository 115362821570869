import { getToken } from 'common/utils/getToken'
import {
    LEAD_GENERATION_API_KEY,
    LEAD_GENERATION_SUBSCRIPTION_KEY,
    LEAD_GENERATION_TOKEN_URL,
    LEAD_GENERATION_URL,
} from 'core'
import { getOnePlanParams } from 'common/components/templates/Calculator/config/onePlanConfig'
import { occupations } from 'common/components/design-system/organisms/CalculatorCms/steps/CalculatorStepDropdown/occupations'

export const createCrmLead = async (data: any, calcType: string) => {
    let crmLead = {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.email,
        mobilePhone: data.phone,
        leadSourceName: '857190005', // ILFS Web
        topic: calcType === 'Pensions' ? '857190000' : '857190001', // Pensions : Protection
        sourceName: 'Retail',
        source: 'Call back',
        callbackScheduledTime: new Date().toISOString(),
        queuerequest: false,
        createGenesysCallback: false,
        interactionId: '00000000-0000-0000-0000-000000000000',
        interactionchannel: 'teleadvice',
        ...getLeadDataByCalcType(data, calcType),
    }
    const getRequestHeaders = async () => {
        const tokenName = 'IL-WebCallBack'
        return {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${
                    (await getToken(LEAD_GENERATION_TOKEN_URL, tokenName)).token
                }`,
                'Ocp-Apim-Subscription-Key': LEAD_GENERATION_SUBSCRIPTION_KEY,
                API_KEY: LEAD_GENERATION_API_KEY,
            },
        }
    }

    fetch(LEAD_GENERATION_URL, {
        ...(await getRequestHeaders()),
        method: 'post',
        body: JSON.stringify(crmLead),
    })
}

const getLeadDataByCalcType = (data: any, calcType: string) => {
    if (calcType === 'One Plan') {
        const plans = getOnePlanParams(data, 'comprehensive', occupations)
        return {
            leadSubSource: '857190022',
            description: 'One Plan Quote',
            areaOfInterest: '971180000',
            age: Number(data.age1),
            OnePlanQuote: {
                CoverFor: 'Life1',
                CoverType: 'Comprehensive',
                OnePlanComprehensiveAmount: Number(
                    data.quoteData.comprehensiveQuoteData.premInclLevy
                ),
                OnePlanEssentialAmount: Number(
                    data.quoteData.essentialQuoteData.premInclLevy
                ),
                OnePlanStandardAmount: Number(
                    data.quoteData.standardQuoteData.premInclLevy
                ),
                IsMortgage: data.mortgage !== 'rent',
                IsRent: data.mortgage === 'rent',
                RentAmount: Number(data.mortgagePayment),
                MortgageAmount: Number(data.mortgagePayment),
                NumberOfChildren: Number(data.childNum1),
                YoungestChildAge: Number(data.youngestChild1),
                Life1BillCoverAmount: Number(plans.billPayAmt1),
                Life2BillCoverAmount: Number(plans.billPayAmt2),
                Life1DecreasingLifeCover: Number(plans.lifeCoverAmt1),
                Life2DecreasingLifeCover: Number(plans.lifeCoverAmt2),
                Life1SpecifiedIllnessCover: Number(plans.specifiedIllnessAmt1),
                Life2SpecifiedIllnessCover: Number(plans.specifiedIllnessAmt2),
                Premium: data.quoteData.comprehensiveQuoteData.premInclLevy,
            },
        }
    }

    if (calcType === 'Mortgage Protection') {
        return {
            leadSubSource: '857190009',
            description: 'Mortgage protection Quote',
            areaOfInterest: '971180007',
            age: Number(data.age1),
            MortgageProtection: {
                SumAssured: data.lifeCoverAmt,
                Term: Number(data.term),
                Premium: data.quoteData.premInclLevy,
                JointLife: data.jointLife === 'TRUE',
                Life2Age: data.age2 ? Number(data.age2) : undefined,
                IsLife1Smoking: data.smokerCd1 === 'Y',
                IsLife2Smoking: data.smokerCd2
                    ? data.smokerCd2 === 'Y'
                    : undefined,
            },
        }
    }
    if (calcType === 'Pensions') {
        return {
            age: data.age,
            leadSubSource: '857190013',
            areaOfInterest: '971180001',
            description: 'Pension Quote',
            PensionQuote: {
                HasPension: data.currentPension === 'Y',
                SectorType: getJobSector(data),
                MonthlyContribution: data.contribution1
                    ? Number(data.contribution1)
                    : Number(data.contribution2),
                EmployerMonthlyContribution: data.jobContribution
                    ? Number(data.jobContribution)
                    : 0,
                PensionViaEmployer: data.pensionThroughWork === 'Y',
                IsEmployed: data.employmentStatus === 'Employed',
                IsSelfEmployed: data.employmentStatus === 'Self employed',
                CurrentPensionValue: Number(data.pensionValue),
                Life1Salary: Number(data.salary),
                EligibleForStatePension: data.isStatePension === 'Y',
                IsPensionThroughEmployer: data.pensionThroughWork === 'Y',
            },
        }
    }
    return {}
}

const getJobSector = (data: any) => {
    if (
        data.employmentStatus === 'Employed' ||
        data.employmentStatus === 'Self employed'
    ) {
        return 'Private'
    } else {
        return 'Public'
    }
}

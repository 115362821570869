import { JumbotronProps } from './Jumbotron'

export const getJumbotronData = (data: any): JumbotronProps => {
    return {
        authors: data.jumbotron__authors,
        image: data.jumbotron__image,
        logo: data.jumbotron__logo,
        title: data.jumbotron__title,
        subtitle: data.jumbotron__subtitle,
        text: data.jumbotron__text,
        variant: data.jumbotron__variant,
        callToAction: getJumbotronCallToActionData(data),
        secondaryCallToAction: getJumbotronSecondaryCallToActionData(data),
        contact: getJumbotronContactData(data),
    }
}

export const getJumbotronCallToActionData = (data: any) => {
    return {
        analyticsId: data.call_to_action__analytics_id,
        label: data.call_to_action__label,
        jumbotronLabel: data.call_to_action__jumbotron_label,
        labelMobile: data.call_to_action__label_mobile,
        [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
        text: data.call_to_action__text,
        title: data.call_to_action__title,
        titleMobile: data.call_to_action__title_mobile,
        url: data.call_to_action__url,
        jumbotronUrl: data.call_to_action__jumbotron_url,
        variant: data.call_to_action__variant,
    }
}

export const getJumbotronSecondaryCallToActionData = (data: any) => {
    return {
        analyticsId: data.call_to_action2__analytics_id,
        label: data.call_to_action2__label,
        [`${data.call_to_action2__icon_location}Icon`]:
            data.call_to_action2__icon,
        url: data.call_to_action2__url,
        variant: data.call_to_action2__variant,
    }
}

export const getJumbotronContactData = (data: any) => {
    return {
        contactTitle: data.jumbotron_contact__title,
        contactText: data.jumbotron_contact__text,
        contactIcon: data.jumbotron_contact__icon,
    }
}

import React from 'react'
import { Box } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { getStyles } from '../../styles/getStyles'
import { Input } from '@irishlife/ilgroupdesignsystem.molecules.input'
import { CalculatorInputType } from 'common/components/organisms/Calculator/utils/interfaces'

export const EmailInput: React.FC<CalculatorInputType> = ({
    register,
    placeholder,
    label,
    fieldName,
    control,
    errorMessage,
    setIsTouched,
    isInvalid,
}) => {
    return (
        <>
            <Controller
                control={control}
                name={fieldName ?? ''}
                defaultValue=''
                rules={{
                    required: errorMessage,
                    validate: (value) =>
                        isValidEmail(value) ? undefined : errorMessage,
                }}
                render={({ onChange, onBlur, value }) => (
                    <Box sx={getStyles({ isInvalid })}>
                        <Input
                            id='email'
                            key={fieldName}
                            label={label}
                            name={fieldName}
                            ref={register}
                            placeholder={placeholder}
                            onBlur={() => setIsTouched && setIsTouched(true)}
                        />
                    </Box>
                )}
            />
        </>
    )
}
const isValidEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )
}

import { Box } from '@chakra-ui/react'
import React from 'react'
import { AssumptionsCard } from './components/AssumptionsCard'
import { BenefitsCard } from './components/BenefitsCard'
import { NextStepsCard } from './components/NextStepsCard'
import { PensionCalculatorOutput } from './components/PensionCalculatorOutput'
import { WarningAlerts } from './components/WarningAlerts'
export interface PensionCalcQuoteProps {
    props?: any
}
export const PensionCalcQuote: React.FC<PensionCalcQuoteProps> = () => {
    return (
        <Box>
            <PensionCalculatorOutput />
            <NextStepsCard />
            <BenefitsCard />
            <AssumptionsCard />
            <WarningAlerts />
        </Box>
    )
}

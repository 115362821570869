import { kentico } from 'common/services/kentico'
import { getValuesFromCmsData } from 'common/utils/getValuesFromCmsData'
import { useQuery } from 'react-query'

export const useSiteData = () => {
    // Get data from Kentico via react-query
    const cmsQuery = useQuery('data', () => kentico.getItem('home'), {
        refetchOnWindowFocus: false
    })
    if (!cmsQuery.data) return null

    return getValuesFromCmsData(cmsQuery.data)
}

import { Box, Grid, GridItem } from '@chakra-ui/react'
import { analyticsService } from 'common/components/organisms/Calculator/config/analytics'
import { useDesktop } from 'common/hooks/useDesktop'
import { createCrmLead } from 'common/services/crm/createCrmLead'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import React, { useEffect } from 'react'
import { EventObject } from 'xstate'
import { onSubmit } from '../../config/pensions'
import { useCalculatorMachine } from '../../utils/context'
import { footerNotesMapper } from '../footerNotes/footerNotesMapper'
import { Heading } from '../Heading'
import ProgressIndicator from '../ProgressIndicator'
import { ViewSelector } from '../views/ViewSelector'
import { ContainerRight } from './container-right'
export interface ContainerProps {
    props?: any
}

export const Container: React.FC<ContainerProps> = ({ props }) => {
    const isDesktop = useDesktop()
    const { send } = useCalculatorMachine()
    const analyticsName = `${props.calc_type}_calculator`

    useEffect(() => {
        fireAnalyticsEvent({
            name: analyticsName,
            properties: {
                event: analyticsName,
                step: 'initialised',
            },
        })
        send({
            type: 'Initialise',
            payload: {
                props,
                actions: { onSubmit, analyticsService, createCrmLead },
            },
        } as EventObject)
        // eslint-disable-next-line
    }, [])

    const calculatorType = props.calc_type
    const FooterNotes = footerNotesMapper[calculatorType]

    return (
        <>
            <ProgressIndicator />
            <Heading
                title={props.tool_label}
                description={props.imagetext}
                icon={props.icon}
                subtitle={props.tool_caption}
            />
            <Grid templateColumns='repeat(8, 1fr)' gap={6}>
                <GridItem colSpan={isDesktop ? 4 : 8}>
                    <ViewSelector props={props} />
                </GridItem>
                {isDesktop && (
                    <GridItem colSpan={4}>
                        <ContainerRight {...props} />
                    </GridItem>
                )}
            </Grid>
            <Box borderBottom='1px solid #C1C7E1' pt='32px' />
            <Box>
                <FooterNotes />
            </Box>
        </>
    )
}
